var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"top-section"},[_c('div',{staticClass:"text"},[_c('h2',{domProps:{"innerHTML":_vm._s(_vm.toLocal('downloads.more_info'))}}),_c('span',[_vm._v(_vm._s(_vm.toLocal("downloads.read")))])])]),_c('div',{staticClass:"game-container"},[_c('div',{staticClass:"title"},[_c('span',[_vm._v(_vm._s(_vm.toLocal('info.game-title')))])]),_c('div',{staticClass:"inner-container"},[_c('div',{staticClass:"desc",domProps:{"innerHTML":_vm._s(_vm.toLocal('info.game-desc'))}}),_c('div',{staticClass:"game-image",on:{"click":function($event){return _vm.goToGame()}}},[_c('img',{attrs:{"src":require("@/assets/images/game.png"),"alt":"Video game image"}})])]),_c('div',{staticClass:"under-button",on:{"click":function($event){return _vm.goToGame()}}},[_c('span',[_vm._v(_vm._s(_vm.toLocal('info.lets-play'))+" ")]),_c('svg',{attrs:{"width":"15","height":"24","viewBox":"0 0 15 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M2 22L12 12L2 2","stroke":"white","stroke-width":"3"}})])])]),_c('div',{staticClass:"information-section"},[_c('span',{staticClass:"info-title"},[_vm._v(_vm._s(_vm.toLocal('info.check-out')))]),_c('div',{staticClass:"downloadables-container"},[_c('div',{staticClass:"downloadable",style:({
                backgroundImage:
                  'url(' + require(`@/assets/images/downloads/Asset10.svg`) + ')',
              }),on:{"click":function($event){return _vm.downloadFile('/downloads/Examples of successful smoke alarm campaigns implemented in various countries.docx', 'Examples of successful smoke alarm campaigns implemented in various countries')}}},[_c('div',{staticClass:"downloadable-title"},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.toLocal('downloads.examples'))+" ")]),_c('div',{staticClass:"icon"},[_c('svg',{attrs:{"width":"37","height":"37","viewBox":"0 0 37 37","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M13.875 0V13.875H4.625L18.5 27.75L32.375 13.875H23.125V0H13.875ZM0 32.375V37H37V32.375H0Z","fill":"white"}})])])])]),_c('div',{staticClass:"downloadable",style:({
                backgroundImage:
                  'url(' + require(`@/assets/images/downloads/Asset11.svg`) + ')',
              }),on:{"click":function($event){return _vm.downloadFile('/downloads/Examples of training programmes for fire services to educate the public about smoke alarms.docx', 'Examples of training programmes for fire services to educate the public about smoke alarms')}}},[_c('div',{staticClass:"downloadable-title"},[_c('div',{staticClass:"text"},[_vm._v(" "+_vm._s(_vm.toLocal('downloads.examples'))+" ")]),_c('div',{staticClass:"icon"},[_c('svg',{attrs:{"width":"37","height":"37","viewBox":"0 0 37 37","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M13.875 0V13.875H4.625L18.5 27.75L32.375 13.875H23.125V0H13.875ZM0 32.375V37H37V32.375H0Z","fill":"white"}})])])])])])]),_c('div',{staticClass:"leaflet-section"},[_c('div',{staticClass:"leaflet-container red-bg"},[_c('h3',{staticClass:"color-text leaflet-title"},[_vm._v(_vm._s(_vm.toLocal("downloads.check_leaf_smoke")))]),_c('span',{staticClass:"color-text"},[_vm._v(_vm._s(_vm.toLocal("downloads.download_leaf")))]),_c('div',{staticClass:"leaflets"},_vm._l((_vm.infoLeaflets),function(leaflet,index){return _c('div',{key:index,staticClass:"leaflet",style:({
              backgroundImage:
                'url(' + require(`@/assets/${leaflet.img}`) + ')',
            }),on:{"click":function($event){return _vm.downloadLeaflet(leaflet)}}},[_c('div',{staticClass:"bottom-content"},[_c('div',{staticClass:"leaflet-text"},[_vm._v(" "+_vm._s(_vm.toLocal(leaflet.text))+" ")]),_c('div',{staticClass:"download-btn"},[_c('svg',{attrs:{"width":"37","height":"37","viewBox":"0 0 37 37","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('path',{attrs:{"d":"M13.875 0V13.875H4.625L18.5 27.75L32.375 13.875H23.125V0H13.875ZM0 32.375V37H37V32.375H0Z","fill":"white"}})])])])])}),0)])]),_c('div',{staticClass:"gif-section"},[_c('div',{staticClass:"gifs-container"},[_c('div',{staticClass:"title-container"},[_c('span',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.toLocal('downloads.gifs'))+" ")])]),_c('div',{staticClass:"inner-container"},[_c('vueper-slides',{staticClass:"no-shadow",attrs:{"infinite":false,"always-refresh-clones":true,"visible-slides":4,"slide-multiple":false,"bullets":false,"gap":4,"slide-ratio":1 / 4,"dragging-distance":200,"fixed-height":"350px","breakpoints":{ 1860: {visibleSlides: 3, slideRatio: 1/3, fixedHeight: false}, 1280: {visibleSlides: 3, slideRatio: 1/4, fixedHeight: false}, 768: { visibleSlides: 1, arrowsOutside: true, slideRatio: 1/1, slideMultiple: 2, fixedHeight: false, }, 1000: { visibleSlides: 2, arrowsOutside: true, slideRatio: 1/2, fixedHeight: false } }}},_vm._l((_vm.gifs),function(gif,idx){return _c('vueper-slide',{key:`gif-${idx}`,scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('img',{staticClass:"single-gif",attrs:{"draggable":"false","src":require(`@/assets/gifs/${_vm.$store.state.lang}/${gif.id}.gif`),"alt":"gif"}})]},proxy:true}],null,true)})}),1)],1)])]),_c('div',{staticClass:"facts-share"},[_c('span',{staticClass:"share-title"},[_vm._v(_vm._s(_vm.toLocal("facts.share_story")))]),_c('button',{on:{"click":_vm.mailto}},[_vm._v("info@eurofsa.org")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }